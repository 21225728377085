/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-02 17:11:51
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-15 09:40:28
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerRole = params => axios({
    url: '/api/base/system/role/listDealer',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerRole = params => axios({
    url: '/api/base/system/role/addDealer',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerRole = params => axios({
    url: '/api/base/system/role/editDealer',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerRole = params => axios({
    url:'/api/base/system/role/delDealer/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerRole = params => axios({
    url: '/api/base/system/role/selectDealerById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
